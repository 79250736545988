import React from 'react'
import Link from 'gatsby-link';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Card } from '@mui/material';
import Layout from '../layouts';
import { graphql } from 'gatsby';

const styles = ({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around'
    },
    maincard: {
        flex: '1 1 auto',
        padding: 20,
        maxWidth: 600,
        marginBottom: 20
    },
    sidebar: {
        flex: '1 1 auto',
        maxWidth: 360,
        width: '100%',
        verticalAlign: 'top'
    },
    sidecard: {
        maxWidth: 360,
        padding: 20,
        verticalAlign: 'top',
        marginBottom: 20
    },
    img: {
        maxWidth: 320,
        width: '100%',
        height: '100%',
        display: 'block',
        transition: '0.3s'
    }
});

const BioPage = (props) => (
    <Layout pageName="Bio">
        <div style={styles.main}>
            <Card style={styles.maincard}>
                <p style={{ marginTop: 0 }}><span style={{ color: '#080', fontWeight: 'bold' }}>Helene Cho is internationally
                    recognized for her expertise</span> in the strategic development of organizations and their people.
                    With over <b>twenty five</b> years of experience in training, consulting, organizational development,
                    and leadership, Helene is particularly skilled in seeing the <b>best</b> in people, and knowing how to
                    bring it out. Helping people and organizations achieve their <b>highest potential</b> is her passion
                    and goal with each client.</p>
                <p>As a former <b>Vice President of a Fortune 500</b> financial institution as well as an international
                    consulting company, Helene knows first hand what challenges leaders face and how
                    to <b>maximize</b> every situation to everyone's benefit. Her insights and recommendations come
                    from years of <b>practical experience</b> and knowing what works.</p>

                <p>Helene is a <b>skilled and inspirational</b> facilitator who effectively uses humor, real life
                    examples and stories, solid and realistic solutions, and participants' input to get <b>results</b> for
                    her clients.</p>

                <p>Helene has lived and worked in Asia, Africa, Europe, and the United States enabling her to bring
                    a <b>rich cultural sense</b> of work and people to her consulting practice.</p>


                <p>In addition to Helene, qualified associates are brought in on a project basis when necessary. We
                    have a highly skilled and diverse pool of consultants and trainers available to serve your needs.</p>

                <p>Helene is a published author. Her book <em>Hamster on a Wheel: Cultivating Work/Life Balance</em> is
                    available through amazon.com. Click <Link to="book">here</Link> to learn more about, or purchase
                    Helene's book.</p>

                <div>
                    <blockquote style={{ color: '#080', maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}>"Work is Love
                        made visible"
                        <br /><br /><small><em>- Kahil Gibran</em></small></blockquote>
                </div>

            </Card>
            <div style={styles.sidebar}>
                <Card style={styles.sidecard}>
                    <div className="sideimg">
                        <GatsbyImage alt="Helene Cho" id="bioimg" style={styles.img} image={props.data.file.childImageSharp.gatsbyImageData} />
                    </div>
                </Card>
            </div>
        </div>
    </Layout>
);

export const query = graphql`{
    file(name: {eq: "HeleneBio"}) {
        childImageSharp {
            gatsbyImageData
        }
    }
}`;

export default BioPage;
